/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

// begin code
var mr_firstSectionHeight,
mr_nav,
mr_fixedAt,
mr_navOuterHeight,
mr_navScrolled = false,
mr_navFixed = false,
mr_outOfSight = false,
mr_floatingProjectSections,
mr_scrollTop = 0;


     // Smooth scroll to inner links
     var innerLinks = $('a.inner-link');

     if(innerLinks.length){
      innerLinks.each(function(){
        var link = $(this);
        var href = link.attr('href');
        if(href.charAt(0) !== "#"){
          link.removeClass('inner-link');
        }
      });

      var offset = 0;
      if($('body[data-smooth-scroll-offset]').length){
        offset = $('body').attr('data-smooth-scroll-offset');
        offset = offset*1;
      }

      smoothScroll.init({
        selector: '.inner-link',
        selectorHeader: null,
        speed: 750,
        easing: 'easeInOutCubic',
        offset: offset
      });
    }

    // Update scroll variable for scrolling functions

    addEventListener('scroll', function() {
      mr_scrollTop = window.pageYOffset;
    }, false);

    // Append .background-image-holder <img>'s as CSS backgrounds

    $('.background-image-holder').each(function() {
      var imgSrc = $(this).children('img').attr('src');
      $(this).css('background', 'url("' + imgSrc + '")');
      $(this).children('img').hide();
      $(this).css('background-position', 'initial');
    });

    // Fade in background images

    setTimeout(function() {
      $('.background-image-holder').each(function() {
        $(this).addClass('fadeIn');
      });
    }, 200);

    // Initialize Tooltips

    $('[data-toggle="tooltip"]').tooltip();

    // Icon bulleted lists

    $('ul[data-bullet]').each(function(){
      var bullet = $(this).attr('data-bullet');
      $(this).find('li').prepend('<i class="'+bullet+'"></i>');
    });

    // Progress Bars

    $('.progress-bar').each(function() {
      $(this).css('width', $(this).attr('data-progress') + '%');
    });

    // Navigation

    if (!$('nav').hasClass('fixed') && !$('nav').hasClass('absolute')) {

        // Make nav container height of nav

        $('.nav-container').css('min-height', $('nav').outerHeight(true));

        $(window).resize(function() {
          $('.nav-container').css('min-height', $('nav').outerHeight(true));
        });

        // Compensate the height of parallax element for inline nav

        if ($(window).width() > 768) {
          $('.parallax:nth-of-type(1) .background-image-holder').css('top', -($('nav').outerHeight(true)));
        }

        // Adjust fullscreen elements

        if ($(window).width() > 768) {
          $('section.fullscreen:nth-of-type(1)').css('height', ($(window).height() - $('nav').outerHeight(true)));
        }

      } else {
        $('body').addClass('nav-is-overlay');
      }

      if ($('nav').hasClass('bg-dark')) {
        $('.nav-container').addClass('bg-dark');
      }


    // Fix nav to top while scrolling

    mr_nav = $('body .nav-container nav:first');
    mr_navOuterHeight = $('body .nav-container nav:first').outerHeight();
    mr_fixedAt = typeof mr_nav.attr('data-fixed-at') !== typeof undefined ? parseInt(mr_nav.attr('data-fixed-at').replace('px', '')) : parseInt($('section:nth-of-type(1)').outerHeight());
    window.addEventListener("scroll", updateNav, false);



    function updateNav() {

    var scrollY = mr_scrollTop;

    if (scrollY <= 0) {
        if (mr_navFixed) {
            mr_navFixed = false;
            mr_nav.removeClass('fixed');
        }
        if (mr_outOfSight) {
            mr_outOfSight = false;
            mr_nav.removeClass('outOfSight');
        }
        if (mr_navScrolled) {
            mr_navScrolled = false;
            mr_nav.removeClass('scrolled');
        }
        return;
    }

    if (scrollY > mr_navOuterHeight + mr_fixedAt) {
        if (!mr_navScrolled) {
            mr_nav.addClass('scrolled');
            mr_navScrolled = true;
            return;
        }
    } else {
        if (scrollY > mr_navOuterHeight) {
           if (!mr_navFixed) {
                mr_nav.addClass('fixed');
                mr_navFixed = true;
            }

            if (scrollY > mr_navOuterHeight +10) {
                if (!mr_outOfSight) {
                    mr_nav.addClass('outOfSight');
                    mr_outOfSight = true;
                }
            } else {
                if (mr_outOfSight) {
                    mr_outOfSight = false;
                    mr_nav.removeClass('outOfSight');
                }
            }
        } else {
            if (mr_navFixed) {
                mr_navFixed = false;
                mr_nav.removeClass('fixed');
            }
            if (mr_outOfSight) {
                mr_outOfSight = false;
                mr_nav.removeClass('outOfSight');
            }
        }

        if (mr_navScrolled) {
            mr_navScrolled = false;
            mr_nav.removeClass('scrolled');
        }

    }
}


    // Menu dropdown positioning

    $('.menu > li > ul').each(function() {
      var menu = $(this).offset();
      var farRight = menu.left + $(this).outerWidth(true);
      if (farRight > $(window).width() && !$(this).hasClass('mega-menu')) {
        $(this).addClass('make-right');
      } else if (farRight > $(window).width() && $(this).hasClass('mega-menu')) {
        var isOnScreen = $(window).width() - menu.left;
        var difference = $(this).outerWidth(true) - isOnScreen;
        $(this).css('margin-left', -(difference));
      }
    });

    // Mobile Menu

    $('.mobile-toggle').click(function() {
      $('.nav-bar').toggleClass('nav-open');
      $(this).toggleClass('active');
    });

    $('.menu li').click(function(e) {
      if (!e) e = window.event;
      e.stopPropagation();
      if ($(this).find('ul').length) {
        $(this).toggleClass('toggle-sub');
      } else {
        $(this).parents('.toggle-sub').removeClass('toggle-sub');
      }
    });

    $('.menu li a').click(function() {
      if ($(this).hasClass('inner-link')){
        $(this).closest('.nav-bar').removeClass('nav-open');
      }
    });

    $('.module.widget-handle').click(function() {
      $(this).toggleClass('toggle-widget-handle');
    });

    $('.search-widget-handle .search-form input').click(function(e){
      if (!e) e = window.event;
      e.stopPropagation();
    });
    
    // Offscreen Nav
    
    if($('.offscreen-toggle').length){
      $('body').addClass('has-offscreen-nav');
    }
    else{
      $('body').removeClass('has-offscreen-nav');
    }
    
    $('.offscreen-toggle').click(function(){
      $('.main-container').toggleClass('reveal-nav');
      $('nav').toggleClass('reveal-nav');
      $('.offscreen-container').toggleClass('reveal-nav');
    });
    
    $('.main-container').click(function(){
      if($(this).hasClass('reveal-nav')){
        $(this).removeClass('reveal-nav');
        $('.offscreen-container').removeClass('reveal-nav');
        $('nav').removeClass('reveal-nav');
      }
    });
    
    $('.offscreen-container a').click(function(){
      $('.offscreen-container').removeClass('reveal-nav');
      $('.main-container').removeClass('reveal-nav');
      $('nav').removeClass('reveal-nav');
    });

        // Populate filters
    
    $('.projects').each(function() {

        var filters = "";

        $(this).find('.project').each(function() {

            var filterTags = $(this).attr('data-filter').split(',');

            filterTags.forEach(function(tagName) {
                if (filters.indexOf(tagName) == -1) {
                    filters += '<li data-filter="' + tagName + '">' + capitaliseFirstLetter(tagName) + '</li>';
                }
            });
            $(this).closest('.projects')
                .find('ul.filters').empty().append('<li data-filter="all" class="active">All</li>').append(filters);
        });
    });

    $('.filters li').click(function() {
        var filter = $(this).attr('data-filter');
        $(this).closest('.filters').find('li').removeClass('active');
        $(this).addClass('active');

        $(this).closest('.projects').find('.project').each(function() {
            var filters = $(this).attr('data-filter');

            if (filters.indexOf(filter) == -1) {
                $(this).addClass('inactive');
            } else {
                $(this).removeClass('inactive');
            }
        });

        if (filter == 'all') {
            $(this).closest('.projects').find('.project').removeClass('inactive');
        }
    });

    // Twitter Feed
       $('.tweets-feed').each(function(index) {
           jQuery(this).attr('id', 'tweets-' + index);
       }).each(function(index) {
           var element = $('#tweets-' + index);
           var TweetConfig = {
               "domId": '',
               "maxTweets": element.attr('data-amount'),
               "enableLinks": true,
               "showUser": true,
               "showTime": true,
               "dateFunction": '',
               "showRetweet": false,
               "customCallback": handleTweets
           };

           if(typeof element.attr('data-widget-id') !== typeof undefined){
                TweetConfig.id = element.attr('data-widget-id');
            }else if(typeof element.attr('data-feed-name') !== typeof undefined && element.attr('data-feed-name') !== "" ){
                TweetConfig.profile = {"screenName": element.attr('data-feed-name').replace('@', '')};
            }else{
                TweetConfig.profile = {"screenName": 'twitter'};
            }

           function handleTweets(tweets) {
               var x = tweets.length;
               var n = 0;
               var element = document.getElementById('tweets-' + index);
               var html = '<ul class="slides">';
               while (n < x) {
                   html += '<li>' + tweets[n] + '</li>';
                   n++;
               }
               html += '</ul>';
               element.innerHTML = html;

               if ($('.tweets-slider').length) {
                    $('.tweets-slider').flexslider({
                        directionNav: false,
                        controlNav: false
                    });
                }       
               return html;
           }
           twitterFetcher.fetch(TweetConfig);
      });

    // Instagram Feed
    
    if($('.instafeed').length){
      jQuery.fn.spectragram.accessData = {
      accessToken: '1406933036.dc95b96.2ed56eddc62f41cbb22c1573d58625a2',
      clientID: '87e6d2b8a0ef4c7ab8bc45e80ddd0c6a'
    };  

        $('.instafeed').each(function() {
            var feedID = $(this).attr('data-user-name');
            $(this).children('ul').spectragram('getUserFeed', {
                query: feedID,
                max: 12
            });
        });
    }   


    // Flickr Feeds

    if($('.flickr-feed').length){
        $('.flickr-feed').each(function(){
            var userID = $(this).attr('data-user-id');
            var albumID = $(this).attr('data-album-id');
            $(this).flickrPhotoStream({ id: userID, setId: albumID, container: '<li class="masonry-item" />' });   
            setTimeout(function(){
                initializeMasonry();
                window.dispatchEvent(new Event('resize'));
            }, 1000); 
        });

    }

     // Image Sliders
    if($('.slider-all-controls, .slider-paging-controls, .slider-arrow-controls, .slider-thumb-controls, .logo-carousel').length){
        $('.slider-all-controls').flexslider({
            start: function(slider){
                if(slider.find('.slides li:first-child').find('.fs-vid-background video').length){
                   slider.find('.slides li:first-child').find('.fs-vid-background video').get(0).play(); 
                }
            },
            after: function(slider){
                if(slider.find('.fs-vid-background video').length){
                    if(slider.find('li:not(.flex-active-slide)').find('.fs-vid-background video').length){
                        slider.find('li:not(.flex-active-slide)').find('.fs-vid-background video').get(0).pause();
                    }
                    if(slider.find('.flex-active-slide').find('.fs-vid-background video').length){
                        slider.find('.flex-active-slide').find('.fs-vid-background video').get(0).play();
                    }
                }
            }
        });
        $('.slider-paging-controls').flexslider({
            animation: "slide",
            directionNav: false
        });
        $('.slider-arrow-controls').flexslider({
            controlNav: false
        });
        $('.slider-thumb-controls .slides li').each(function() {
            var imgSrc = $(this).find('img').attr('src');
            $(this).attr('data-thumb', imgSrc);
        });
        $('.slider-thumb-controls').flexslider({
            animation: "slide",
            controlNav: "thumbnails",
            directionNav: true
        });
        $('.logo-carousel').flexslider({
            minItems: 1,
            maxItems: 4,
            move: 1,
            itemWidth: 200,
            itemMargin: 0,
            animation: "slide",
            slideshow: true,
            slideshowSpeed: 3000,
            directionNav: false,
            controlNav: false
        });
    }

// end code

},
finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
